.gradienttext{
  background: linear-gradient(90deg, #EC568E 0%, #3110B5 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.heading{
  text-align: center;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}



@tailwind base;
@tailwind components;
@tailwind utilities;


.bullet{
  background: black;
 
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 10px;

}